<template>
  <div>
		<div class="row row-cols-2 row-cols-md-4">
      <div class="col mb-4" v-for="(escort, index) in latest" v-bind:key="index">
         <a :href="'/escort-gallery/'+escort.slug">
          <div class="card h-100 profile" :style="{ backgroundImage: `url('/storage/photo/pics/${escort.thumb}')` }" :title="escort.w_name+' - ' +escort.galCaption">
            <div class="card-header">
              {{ escort.display_name }}<br><div class="sub-header"> {{ escort.nationality }}</div>
            </div>
            <div class="card-body"></div>
            <div class="card-footer">
                <!-- {{ escort.age_1 }} yrs | {{ escort.dress }} | {{ escort.bust }}  -->
                 {{ escort.age_1 }} years old, {{ escort.bust }}
            </div>
          </div>
         </a>
        </div>
    </div>
  </div>
</template>
<script>
	export default {
		props: ['latestEscorts'],
		data() {
			return {
				latest: this.latestEscorts
			}
		}

	}
</script>