<template>
	<div>
		
	<transition name="slide-fade">
	<div v-if="reviewForm">
		<h2 class="mt-4">Leave {{ girl.charAt(0).toUpperCase() + girl.slice(1)  }} a review</h2>
		<p class="lead">
			If you have enjoyed {{ girl.charAt(0).toUpperCase() + girl.slice(1)  }}s company and you would like to leave her a review, then please just complete the form below. 
		</p>
		<div class="alert alert-danger" v-if="errors.length">
		    <b>Please correct the following error(s):</b>
		    <ul>
		      <li v-for="error in errors">{{ error }}</li>
		    </ul>
		  </div>
		
		<form method="get" accept-charset="utf-8" v-on:submit.prevent>
		
			<div class="form-row">
		    <div class="form-group col-md-6">
		      
		      <input type="text" class="form-control" id="name" v-model="name" placeholder="Your name">
		    </div>
		    <div class="form-group col-md-6">
		      
		      <input type="text" class="form-control" id="area" v-model="area" placeholder="Your location">
		    </div>
		  </div>
		  <div class="form-group">
		    
		    <textarea name="review" id="review" class="w-100 rounded-lg p-2" v-model="review" rows="5" placeholder="Leave your review here"></textarea>
		  </div>
		  <button class="btn btn-outline-success btn-lg" @click="leaveReview()">Leave your review for {{ girl.charAt(0).toUpperCase() + girl.slice(1)  }}</button>
		</form>
		
		
	
		 
	</div>

	<div v-else>
		<h2>Thankyou for leaving {{ girl.charAt(0).toUpperCase() + girl.slice(1)  }} a review</h2>
		<p class="lead">
			Thanks for leaving Eve a review, your review will help other customers who are looking to choose {{ girl.charAt(0).toUpperCase() + girl.slice(1)  }} as their escort.
		</p>
	</div>
</transition>	
	</div>
</template>

<script>
	
	export default {

		props: ['csrf', 'escort'],
		data() {
			return {
				girl: this.escort,
				reviewForm: true,
				errors: [],
				name: '',
				area: '',
				review: ''

			}
		},
		methods: {
			checkForm: function (e) {
		     
		      if (this.name && this.area && this.review) {
		        return true;
		      }

		      this.errors = [];

		      if (!this.name) {
		        this.errors.push('Your name is required.');
		      }
		      if (!this.area) {
		        this.errors.push('Your area is required.');
		      }
		      if (!this.review) {
		        this.errors.push('You need to leave a review.');
		      }

		    },

			leaveReview() {

				if(this.checkForm() == true){
				this.errors = [];

				const reviewData = new FormData();

				reviewData.append('punter_name', this.name);
				reviewData.append('punter_area', this.area);
				reviewData.append('punter_comment', this.review);
				reviewData.append('escort', this.girl);
				this.reviewForm = false;
				axios.post('/api/leavereview', reviewData, { headers: {'X-CSRFToken': this.csrf} })
                    .then(function (response) {
						    console.log(response);
						  })
                    .catch(error => console.log(error.data));
                 }
             }
			
		}


	}
</script>