<template>
<div>
<div class="row row-cols-2 row-cols-md-4" >

	<div v-for="(escort, index) in number" v-bind:key="escort" class="col mb-4">
		<div v-if="index < total">
			<a :href="'/escort-gallery/'+escorts[index].slug">
				<div class="card h-100 profile" :style="{ backgroundImage: `url('/storage/photo/pics/${escorts[index].photo}')`, width: `` }" :title="escorts[index].w_name+' - ' +escorts[index].galCaption">
					<div class="card-header">
						{{ escorts[index].display_name }}<br><div class="sub-header">{{ escorts[index].nationality }}</div>
					</div>

					<div class="card-body"> </div>
					<div class="card-footer">
						{{ escorts[index].age_1 }} years old, {{ escorts[index].bust }}
					</div>

				</div>
			</a>
		</div>

	</div>
</div>
	<button v-if="escorts.length > number" class="btn btn-lg btn-outline-light mb-5 mt-3 escort_button" @click="number += 12">Show more escorts</button>

</div>
</template>

<script>
	export default {
		props: ['data'],
		data() {
			return {
				escorts: this.data,
				number: 24,
				total: this.data.length
			}
		},

	}
</script>
<style>
    .text-sm {
        font-size: 0.600rem;
        font-style: italic;
        text-transform: uppercase;
    }
    .strapline {
        background-color: #222;
    }
</style>
